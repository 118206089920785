import React from "react";
import Layout from "../components/Layout";

export default function Contact() {
  return (
    <Layout>
      <div>
        <h1 style={{ color: "#3399f3", marginBottom: "30px" }}>Contact Us</h1>
        <div
          style={{ marginTop: "-10px", fontSize: "1.2em" }}
          className="faqContent"
        >
          <p>
            If you have any questions about BASIC Cognitive, please contact our
            field office at 361-257-1239. You can also E-mail:
            <a
              href="mailto:umhs-basiccognitive@med.umich.edu"
              className="callLink"
            >
              umhs-basiccognitive@med.umich.edu
            </a>
            .
          </p>
          <h3>
            <a
              href="#mailing-address"
              id="mailing-address"
              className="callLink"
            >
              Mailing address
            </a>
          </h3>
          <p>
            BASIC Cognitive Project
            <br /> 5151 Flynn Pkwy. Suite 506,
            <br /> Corpus Christi, TX 78411
            <br /> Phone:361-257-1239
          </p>
          <h3>
            <a
              href="#project-manager"
              id="project-manager"
              className="callLink"
            >
              Project Manager
            </a>
          </h3>
          <p>
            Nelda Garcia, BS
            <br /> E-mail:
            <a href="mailto:neldag@med.umich.edu" className="callLink">
              neldag@med.umich.edu
            </a>
            <br />
            Phone:361-461-1826
          </p>
          <h3>
            <a
              href="#institutional-review-board"
              id="institutional-review-board"
              className="callLink"
            >
              Institutional Review Board
            </a>
          </h3>
          <p>
            If you have questions about research participant rights, or wish to
            obtain information, ask questions or discuss concerns about this
            study with someone other than the researchers, please contact the
            University of Michigan Medical School Institutional Review Board
            (IRBMED).
          </p>
          <p>
            E-mail:
            <a href="mailto:irbmed@umich.edu" className="callLink">
              irbmed@umich.edu
            </a>
            <br /> Phone:734-763-4768
          </p>
          <h3>
            <a
              href="#mailing-address"
              id="mailing-address"
              className="callLink"
            >
              Mailing Address
            </a>
          </h3>
          <p>
            University of Michigan Medical School Institutional Review Board
            (IRBMED)
            <br /> 2800 Plymouth Road,
            <br /> Building 520, Room 3214
            <br /> Ann Arbor, MI 48109
          </p>
          <p>
            If you are concerned about a possible violation of your privacy,
            contact the University of Michigan Health System Privacy Officer.
            <br /> Telephone 1-888-296-2481
          </p>
        </div>
      </div>
    </Layout>
  );
}